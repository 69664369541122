<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:38
-->
<template>
	<div class="volunteerList">
		<div class="volunteerList-content">
			<div class="volunteerList-search">
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">学号</span>
						<el-input class="common-row-ipt" placeholder="请输入学号" v-model="searchForm.studentId" />
					</div>
					<div class="common-row-item">
						<span class="common-row-label">姓名</span>
						<el-input class="common-row-ipt" placeholder="请输入姓名" v-model="searchForm.name" />
					</div>
					<div style="margin: 0 0;" class="common-row-item common-row-default flex-between">
						<div class="flex-start">
							<el-button @click="search" type="primary">查询</el-button>
							<el-button @click="reset">重置</el-button>
						</div>
						<el-button @click="volunteerDownload">导出数据</el-button>
					</div>
				</div>
			</div>
			<div class="volunteerList-table">
				<el-table :data="tableData" border>
					<el-table-column width="160px" prop="regNum" label="注册号">
						<template slot-scope="scope">
							<div class="action-bu">
								<span>{{scope.row.regNum}}</span>
								<img v-if="scope.row.fillUp" src="@/assets/images/common/bu.png" alt="">
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="姓名">
					</el-table-column>
					<el-table-column width="100px" prop="gender" label="性别">
						<template slot-scope="scope">
							<span>{{scope.row.gender == true?"男":"女"}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="studentId" label="学号">
					</el-table-column>
					<el-table-column prop="collegeName" label="学院班级">
						<template slot-scope="scope">
							<span>{{scope.row.collegeName}}{{scope.row.faculty}}</span>
						</template>
					</el-table-column>
					<el-table-column width="100px" prop="creditScore" label="总分">
					</el-table-column>
					<el-table-column width="100px" prop="workingHours" label="总工时">
					</el-table-column>
					<el-table-column width="100px" prop="efficient" label="星级工时">
					</el-table-column>
					<el-table-column prop="studentId" label="加/减分记录">
						<template slot-scope="scope">
							<div class="table-action">
								<el-popover placement="top" width="400" trigger="click">
									<div v-if="recodsDetailList.length > 0" class="table-action-records">
										<div v-for="(item, index) in recodsDetailList" :key="index"
											class="action-records-item"
											:class="{'action-records-borderL': index != (recodsDetailList.length - 1)}">
											<div class="records-item-step">
												<div class="item-step-circle"
													:class="{'step-circle-addbox': item.addAndSub == 1, 'step-circle-cutbox': item.addAndSub == 0}">
													<span
														:class="{'step-circle-addinner': item.addAndSub == 1, 'step-circle-cutinner': item.addAndSub == 0}"></span>
												</div>
												<span class="item-step-val"
													:class="{'step-val-add': item.addAndSub == 1, 'step-val-cut': item.addAndSub == 0}">{{item.addAndSub == 1?'+':item.addAndSub == 0?'-':''}}{{item.fraction}}分</span>
											</div>
											<div class="records-item-row">
												<div class="records-item-col">
													<span class="records-item-label">活动ID：</span>
													<span class="records-item-value">{{item.activityId==="0"?'无':item.activityId}}</span>
												</div>
												<div class="records-item-col">
													<span class="records-item-label">记录时间：</span>
													<span class="records-item-value">{{item.createTime}}</span>
												</div>
											</div>
											<div class="records-item-row">
												<div class="records-item-col">
													<span class="records-item-label">活动名称：</span>
													<span class="records-item-value">{{item.activityName}}</span>
												</div>
											</div>
											<div class="records-item-row">
												<div class="records-item-col">
													<span class="records-item-label">活动时间：</span>
													<span class="records-item-value">{{item.activityTime}}</span>
												</div>
											</div>
											<div class="records-item-row">
												<div class="records-item-col">
													<span class="records-item-label">加分/减分原因：</span>
													<span
														class="records-item-value">{{item.reason}}{{item.reasonDescribe}}</span>
												</div>
											</div>
											<div class="records-item-row">
												<div class="records-item-col">
													<span class="records-item-label">备注：</span>
													<span class="records-item-value">{{item.remark}}</span>
												</div>
											</div>
											<div class="records-item-row">
												<div class="records-item-col">
													<span class="records-item-label">记录人：</span>
													<span class="records-item-value">{{item.recorder}}</span>
												</div>
											</div>
										</div>
									</div>
									<div v-else class="table-action-records">
										<div class="action-records-empty">
											<img src="@/assets/images/common/empty.png" alt="">
											<span>暂无加/减分记录~</span>
										</div>
									</div>
									<span slot="reference" @click="actionFn('detail', scope)">查看</span>
								</el-popover>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="updateTime" label="最近更新时间">
					</el-table-column>
					<el-table-column width="140px" prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('change', scope)">改分</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
		<el-dialog @close="closeDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
			:visible.sync="dialogVisible" width="620px">
			<div slot="title" class="dialog-header">
				<span>{{dialogTitle}}</span>
				<img @click="closeDialog" src="@/assets/images/common/close.png">
			</div>
			<el-form :model="changeRecordsForm" :rules="changeRecordsRules" ref="changeRecordsForm" label-width="120px"
				class="">
				<el-form-item class="volunteerList-dia-formitem" label="活动:" prop="activityId">
					<el-select @change="activityChange" class="volunteerList-dia-select"
						v-model="changeRecordsForm.activityId" placeholder="请选择">
						<el-option v-for="item in activityList" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="volunteerList-dia-formitem" label="加分/减分:" prop="addAndSub">
					<div class="flex-column">
						<el-radio-group class="volunteerList-dia-radio" @change="radioChange"
							v-model="changeRecordsForm.addAndSub">
							<el-radio :label="'1'">加分</el-radio>
							<el-radio :label="'0'">减分</el-radio>
						</el-radio-group>
						<el-input class="volunteerList-dia-ipt" v-model="changeRecordsForm.fraction" placeholder="请输入">
						</el-input>
					</div>
				</el-form-item>
				<el-form-item class="volunteerList-dia-formitem" label="加分/减分原因:" prop="reason">
					<div class="flex-column">
						<!-- <el-radio-group class="volunteerList-dia-radio" v-model="changeRecordsForm.reason">
							<el-radio v-for="(item,index) in changeRecordsReason" :key="index" :label="item.value">
								{{item.label}}
							</el-radio>
						</el-radio-group> -->
						<el-checkbox-group v-for="(item, index) in changeRecordsReason" :key="index" @change="volunteerChange" v-model="recordsReasonList">
							<el-checkbox v-for="(citem, cindex) in item" :key="cindex"
								:disabled="citem.disabled" :label="citem.label">{{citem.label}}</el-checkbox>
						</el-checkbox-group>
						<el-input :readonly="otherDisable" type="textarea" class="volunteerList-dia-textarea"
							v-model="changeRecordsForm.reasonDescribe" placeholder="请输入">
						</el-input>
					</div>
				</el-form-item>
				<el-form-item class="volunteerList-dia-formitem" label="备注:" prop="remark">
					<el-input type="textarea" class="volunteerList-dia-textarea" v-model="changeRecordsForm.remark"
						placeholder="请输入">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialog">取 消</el-button>
				<el-button v-preventReClick @click="submitDialog" type="primary">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		listVolunteer,
		changeRecord,
		activityVolunteer,
		detailChangeRecords,
		downloadVolunteer,
	} from "@/api/volunteer";
	import {
		getEnumKeyList
	} from "@/api/index.js";
	import storage from 'store';
	export default {
		data() {
			const checkReason = (rule, value, callback) => {
				if (this.recordsReasonList.length == 0) {
					callback(new Error("请选择加分/减分原因"));
				} else {
					if (this.recordsReasonList.includes('其他原因')) {
						if (!this.changeRecordsForm.reasonDescribe) {
							callback(new Error("请输入其他加分/减分原因"));
						} else {
							callback();
						}
					}
					callback();
				}
				callback();
			};
			const checkAddAndSub = (rule, value, callback) => {
				if (!value) {
					callback(new Error("请选择加分/减分"));
				} else {
					if (!this.changeRecordsForm.fraction) {
						callback(new Error("请输入加分/减分分数"));
					} else {
						callback();
					}
				}
				callback();
			};
			return {
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				searchForm: {},
				dialogVisible: false,
				dialogTitle: "",
				changeRecordsForm: {
					addAndSub: '1',
				},
				changeRecordsRules: {
					activityId: [{
						required: true,
						message: "请选择活动",
						trigger: "blur"
					}],
					addAndSub: [{
						required: true,
						validator: checkAddAndSub,
						trigger: "blur"
					}],
					reason: [{
						required: true,
						validator: checkReason,
						trigger: "blur"
					}],
				},
				changeRecordsReason: [],
				activityList: [],
				recodsDetailList: [
					// {
					// 	id: 10000,
					// 	createTime: "2022-07-07 23:59:59",
					// 	name: "莫干山羽毛球大赛志愿者活动",
					// 	startTime: "2022-07-15",
					// 	reason: "杭州荣鼎科技是一家现代服务型的科技企业，专注于企业服务领域，致力于成为100万家企业的首选合作伙伴。荣鼎始终坚守让每一份信任不被辜负的使命，坚持全直营的互联网企业服务。旗下有IT技术服务、人力资源服务、职业教育服务、数字营销服务四大直营业务板块。拥有职通通APP和同行圈APP两大互联网平台品牌。",
					// 	mark: "表现很好",
					// 	user: "李飞（材料分会）",
					// 	type: 1,
					// 	records: 5
					// },
				],
				recordsReasonList: [],
				otherDisable: false,
			};
		},
		watch: {
			dialogVisible: {
				handler(val) {
					if (!val) {
						this.$nextTick(() => {
							this.dialogTitle = ""
							this.changeRecordsForm = {}
							this.recordsReasonList = []
							this.otherDisable = false
							this.$refs["changeRecordsForm"].clearValidate()
						})
					}
				},
				deep: true
			}
		},
		mounted() {
			this.getVolunteerList();
		},
		methods: {
			radioChange(e) {
				console.log("radioChange", e)
				this.changeRecordsForm.addAndSub = e
			},
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(" 00:00:00", "")
				} else {
					return ""
				}
			},
			reasonFormat(intReason) {
				if (intReason) {
					let reasonArr = intReason.split(",")
					let newArr = []
					reasonArr.forEach(item => {
						this.changeRecordsReason.forEach(citem => {
							if (item == citem.value) {
								newArr.push(citem.label)
							}
						})
					})
					return newArr.join(",")
				} else {
					return ""
				}
			},
			volunteerChange() {
				// console.log("deductionChange", this.recordsReasonList, this.recordsReasonList.indexOf('其他原因'))
				if (this.recordsReasonList.length > 0) {
					if (this.recordsReasonList.indexOf('志愿者培训') > -1) {
						this.otherDisable = true
						this.changeRecordsReason[1].forEach(item => item.disabled = true)
						this.changeRecordsReason[2].forEach(item => item.disabled = true)
					} else if (this.recordsReasonList.indexOf('其他原因') > -1) {
						this.changeRecordsReason[0].forEach(item => item.disabled = true)
						this.changeRecordsReason[1].forEach(item => item.disabled = true)
					} else {
						this.otherDisable = true
						this.changeRecordsReason[0].forEach(item => item.disabled = true)
						this.changeRecordsReason[2].forEach(item => item.disabled = true)
					}
				} else {
					this.otherDisable = false
					this.$set(this.changeRecordsForm, "reasonDescribe", "")
					this.changeRecordsReason[0].forEach(item => item.disabled = false)
					this.changeRecordsReason[1].forEach(item => item.disabled = false)
					this.changeRecordsReason[2].forEach(item => item.disabled = false)
				}
			
			},
			// volunteerChange() {
			// 	console.log("deductionChange", this.recordsReasonList, this.recordsReasonList.indexOf('其他原因'))
			// 	if (this.recordsReasonList.length > 0) {
			// 		if (this.recordsReasonList.indexOf('其他原因') > -1) {
			// 			this.changeRecordsReason.forEach(item => {
			// 				if (item.label == '其他原因') {
			// 					item.disabled = false
			// 				} else {
			// 					item.disabled = true
			// 				}
			// 			})
			// 		}
			// 		if (this.recordsReasonList.indexOf('其他原因') == -1) {
			// 			this.otherDisable = true
			// 			this.$set(this.changeRecordsForm, "reasonDescribe", "")
			// 			this.changeRecordsReason.forEach(item => {
			// 				if (item.label == '其他原因') {
			// 					item.disabled = true
			// 				}
			// 			})
			// 		}
			// 	} else {
			// 		this.otherDisable = false
			// 		this.$set(this.changeRecordsForm, "reasonDescribe", "")
			// 		this.changeRecordsReason.forEach(item => {
			// 			item.disabled = false
			// 		})
			// 	}

			// },
			activityChange(e) {
				console.log("activityChange", e)
				if (e) {
					let filterArr = this.activityList.filter(item => item.value == e)
					this.changeRecordsForm.activityName = filterArr[0].label
				} else {
					this.changeRecordsForm.activityName = ""
				}
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.getVolunteerList();
			},
			getVolunteerActivity(id) {
				activityVolunteer({
					volunteerId: id
				}).then((res) => {
					if (res.code == 0) {
						if (res.data && res.data.length > 0) {
							this.activityList = res.data.map(item => {
								return {
									activityType: item.activityType,
									label: item.activityName,
									value: item.id,
								}
							})
						}
					}
				})
			},
			volunteerDownload() {
				this.getVolunteerList(2)
			},
			getVolunteerList(type) {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				if (this.searchForm.studentId) {
					this.$set(params, "studentId", this.searchForm.studentId)
				}
				if (this.searchForm.name) {
					this.$set(params, "name", this.searchForm.name)
				}
				if (type == 2) {
					downloadVolunteer(params).then((res) => {
						console.log("downloadVolunteer", res)
						const blob = new Blob([res], {
							type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						})
						// const blob = new Blob([res], {type: "application/vnd.ms-excel;charset=utf-8"})
						if ('download' in document.createElement('a')) {
							// 非IE下载
							const elink = document.createElement('a')
							elink.download = "志愿者列表.xlsx"
							elink.style.display = 'none'
							elink.href = URL.createObjectURL(blob)
							document.body.appendChild(elink)
							elink.click()
							URL.revokeObjectURL(elink.href) // 释放URL 对象
							document.body.removeChild(elink)
						} else {
							// IE10+下载
							navigator.msSaveBlob(blob, "志愿者列表.xlsx")
						}
					})
				} else {
					listVolunteer(params).then((res) => {
						if (res.code == 0) {
							this.tableData = res.data
							this.totalCount = res.page.totalCount * 1
						}
					})
				}
			},
			getChangeRecordsDetail(id) {
				detailChangeRecords({
					informationId: id,
					pageIndex: 1,
					pageSize: 999
				}).then((res) => {
					if (res.code == 0) {
						this.recodsDetailList = res.data
					}
				})
			},
			search() {
				this.pageIndex = 1;
				this.tableData = [];
				this.getVolunteerList();
			},
			reset() {
				this.searchForm = {}
				this.pageIndex = 1;
				this.tableData = [];
				this.getVolunteerList();
			},
			actionFn(type, scope) {
				switch (type) {
					case "change":
						this.getVolunteerActivity(scope.row.id)
						this.getChangeRecordsReasonList();
						this.$set(this.changeRecordsForm, "informationId", scope.row.id)
						this.dialogTitle = `改分（${scope.row.name}）`
						this.dialogVisible = true
						break;
					case "detail":
						this.getChangeRecordsReasonList()
						this.getChangeRecordsDetail(scope.row.id)
						break;
				}
			},
			closeDialog() {
				this.dialogVisible = false
			},
			submitDialog() {
				this.$refs["changeRecordsForm"].validate(valid => {
					if (valid) {
						let params = JSON.parse(JSON.stringify(this.changeRecordsForm))
						let reasonArr = JSON.parse(JSON.stringify(this.recordsReasonList))
						params.reason = reasonArr.join(",")
						// params.recorder = storage.get('ACCESS_Info').realname
						console.log("submitDialog", params)
						changeRecord(params).then((res) => {
							if (res.code == 0) {
								this.$message({
									type: 'success',
									message: '改分成功!'
								});
								this.dialogVisible = false
								this.getVolunteerList();
							}
						})
					}
				})
			},
			getChangeRecordsReasonList() {
				getEnumKeyList({
					enumKey: "DEDUCTION_REASONS"
				}).then((res) => {
					let rowOne = []
					let rowTwo = []
					let rowThree = []
					this.changeRecordsReason = []
					res.data.forEach(item => {
						if(item.value == '志愿者培训') {
							rowOne.push({
								label: item.value,
								value: item.key,
								disabled: false
							})
						} else if (item.value == '其他原因' || item.value == '其他') {
							rowThree.push({
								label: item.value,
								value: item.key,
								disabled: false
							})
						} else {
							rowTwo.push({
								label: item.value,
								value: item.key,
								disabled: false
							})
						}
					})
					this.changeRecordsReason = [rowOne, rowTwo, rowThree]
					// this.changeRecordsReason = res.data.map(item => {
					// 	return {
					// 		label: item.value,
					// 		value: item.key,
					// 		disabled: false
					// 	}
					// })
					// this.changeRecordsReason = this.changeRecordsReason.filter(item => (item.label != "其他" && item.label != "其他原因"))
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.el-button {
		height: 36px;
	}

	/deep/.volunteerList-dia-formitem .el-textarea__inner {
		resize: none;
		height: 80px;
		border: 0;
	}

	/deep/.common-row-default .el-button--default {
		border: 1px solid #DCDFE6 !important;
		color: #606266 !important;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.flex-start {
		display: flex;
		align-items: flex-start;
	}

	.flex-between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.action-bu {
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 16px;
			height: 16px;
			margin-left: 5px;
		}
	}

	.action-records-empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		img {
			width: 100px;
			height: 100px;
			margin-bottom: 6px;
		}
	}

	.volunteerList {
		.volunteerList-dia-formitem {
			margin: 20px 20px 0 0;

			.volunteerList-dia-radio {
				margin: 12px 0 10px 0;
			}

			.volunteerList-dia-ipt {
				border: 1px solid #DCDFE6;
				border-radius: 4px;
				width: 460px;
			}

			.volunteerList-dia-select {
				border: 1px solid #DCDFE6;
				border-radius: 4px;
				width: 460px;
			}

			.volunteerList-dia-textarea {
				border: 1px solid #DCDFE6;
				border-radius: 4px;
				width: 460px;
			}
		}

		.volunteerList-content {
			padding: 0 20px 20px 20px;


			.volunteerList-search {
				.common-row {
					margin: 16px 0;

					.common-row-item {
						margin-right: 20px;

						.common-row-ipt {
							flex: 1;
							height: 36px;
							line-height: 36px;
							border: 1px solid #EDF1F7;
							border-left: 0;
						}
					}
				}
			}

			.volunteerList-table {
				margin-top: 20px;


			}
		}
	}
</style>
